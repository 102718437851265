<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="userData === undefined">
      <h4 class="alert-heading">
        {{ $t("xErrorFetch", { value: $t("user") }) }}
      </h4>
      <div class="alert-body">
        {{ $t("xNotFound", { value: $t("user") }) }}
        <b-link class="alert-link" :to="{ name: 'apps-users-list' }" />
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col cols="12">
          <user-view-user-info-card :user-data="userData" />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <user-view-user-timeline-card :user-data="userData" />
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { BRow, BCol, BAlert, BLink } from "bootstrap-vue";
import UserViewUserInfoCard from "./UserViewUserInfoCard.vue";
import UserViewUserTimelineCard from "./UserViewUserTimelineCard.vue";
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    UserViewUserInfoCard,
    UserViewUserTimelineCard,
  },
  data: function () {
    return {
      userData: {},
    };
  },
  async mounted() {
    await store.dispatch("usersModule/fetchUser", {
      id: router.currentRoute.params.id,
    });
    this.userData = store.state.usersModule.selectedUser;
  },
};
</script>
