import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";
import VueI18n from "@/libs/i18n";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useUserList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { label: VueI18n.t("name"), key: "name", sortable: true },
    { key: "surname", label: VueI18n.t("lastName"), sortable: true },
    {
      key: "email",
      label: VueI18n.t("email"),
      formatter: title,
      sortable: true,
    },
    { label: VueI18n.t("isActive"), key: "active", sortable: false },
    { key: "details", label: VueI18n.t("detail") },
    { key: "delete", label: VueI18n.t("delete") },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const typeFilter = ref(null);
  const genderFilter = ref(null);
  const statusFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch(
    [currentPage, perPage, searchQuery, typeFilter, genderFilter, statusFilter],
    () => {
      refetchData();
    }
  );

  const deleteUser = (ctx) => {
    store
      .dispatch("usersModule/deleteUser", { id: ctx })
      .then((_res) => {
        //        refetchData();
        refUserListTable.value && refetchData();
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("deleted", { type: VueI18n.t("user") }),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      })
      .catch((_e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("notDeleted", { type: VueI18n.t("user") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  const fetchUsers = (ctx, callback) => {
    var userData = JSON.parse(localStorage.getItem("userData"));

    store
      .dispatch("usersModule/fetchUsers", {
        id: userData.companyid,
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        type: typeFilter.value,
        gender: genderFilter.value,
        status: statusFilter.value,
      })
      .then((response) => {
        const users = response.data;
        callback(users);
        totalUsers.value = users.list;
      })
      .catch((_e) => {
        toast({
          component: ToastificationContent,
          props: {
            text: VueI18n.t("xFetchError", { value: VueI18n.t("user") }),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    deleteUser,

    refetchData,

    // Extra Filters
    typeFilter,
    genderFilter,
  };
}
